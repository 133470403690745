.our-work-carousel {
    .slide {
        .carousel-slide {
            display: flex;
            img {
                width: 50% !important;
                height: auto;
            }
        }
    }
    @media only screen and (max-width: $mobile) {
        // margin: 0 -20px;
        .slide {
            .carousel-slide {
                flex-direction: column;
                img {
                    width: 100% !important;
                }
            }
        }
    }
}