section {
    position: relative;
    padding: 80px 0;
    &.services {
        padding-bottom: 50px;
    }
    &.about-us,
    &.contact-us {
        background-color: $dark-grey;
    }
    @media only screen and (max-width: $mobile) {
        padding: 60px 0;
        &.services {
            padding-bottom: 20px;
        }
    }
}

.anchor-link {
    position: absolute;
    top: - $header-height-minimised;
    @media only screen and (max-width: 1000px) {
        top: -$mobile-header-height;
    }
}

.container {
    max-width: 1800px;
    padding: 0 40px;
    margin: 0 auto;
    @media only screen and (max-width: 1920px) {
        max-width: 1100px;
    }
    @media only screen and (max-width: 1000px) {
        padding: 0 20px;
    }
}

.contact-wrapper {
    display: flex;
    justify-content: center;
}