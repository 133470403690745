.navigation {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $dark-grey;
    z-index: 99;
    box-shadow: 2px 3px 11px black;
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        height: $header-height;
        transition: height .3s ease-in-out;
        &.minimised {
            height: $header-height-minimised;
            img {
                height: $header-height-minimised - 10px;
            }
        }
        img {
            height: $header-height - 10px;
            width: auto;
            transition: height .3s ease-in-out;
        }
        .header-links {
            ul {
                display: flex;
                li {
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    a {
                        color: white;
                        font-weight: 600;
                        font-size: 22px;
                        text-decoration: none;
                        &:before {
                            position: absolute;
                            content: '';
                            left: 0;
                            width: 0;
                            bottom: 0;
                            height: 2px;
                            background-color: white;
                            transition: width .3s ease-in-out;
                        }
                        &:hover {
                            &:before {
                                width: 100%;
                            }
                        }
                        img {
                            width: 30px;
                            height: auto;
                            @media only screen and (max-width: $mobile) {
                                width: 25px;
                            }
                        }
                    }
                    &:last-of-type,
                    &:nth-of-type(6) {
                        a {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    nav {
        position: fixed;
        top: $mobile-header-height;
        left: 100%;
        width: 100%;
        height: calc(100% - $mobile-header-height);
        background-color: $dark-grey;
        transition: left 0.4s ease-in-out;
        &.show-nav {
            left: 0;
        }
        h2 {
            margin: 20px 0 0 40px;
            font-size: 24px;
        }
        ul {
            display: flex;
            flex-direction: column;
            margin: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -60%);
            li {
                padding: 20px;
                text-align: center;
                a {
                    color: white;
                    text-decoration: none;
                    font-size: 20px;
                    font-weight: 600;
                    &::before {
                        position: absolute;
                        content: '';
                        bottom: 1px;
                        background-color: white;
                        width: 100%;
                        height: 1px;
                    }
                }
            }
        }
        .facebook,
        .instagram {
            position: absolute;
            bottom: 30px;
            img {
                width: 25px;
                height: auto;
            }
        }
        .facebook {
            left: 20px;
        }
        .instagram {
            right: 20px;
        }
    }
    .burger-menu {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        display: none;
        cursor: pointer;
        padding: 10px;
        .burger-menu-container {
            position: relative;
            width: 20px;
            height: 14px;
            span {
                background-color: white;
                height: 2px;
                position: absolute;
                width: 100%;
                left: 0;
                transition: all 0.4s ease;
                transform: rotate(0);
                -webkit-backface-visibility: hidden;
                &:first-child {
                    top: 0;
                }
                &:nth-child(2) {
                    top: 6px;
                }
                &:last-child {
                    top: 12px;
                }
            }
        }
        &.cross {
            .burger-menu-container {
                span {
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:first-child,
                    &:last-child {
                        top: 6px;
                        background-color: white;
                    }
                    &:first-child {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }
                    &:last-child {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .navigation {
        header {
            height: $mobile-header-height;
            img {
                height: $mobile-header-height - 10px;
            }
            .header-links {
                padding-right: 40px;
                ul {
                    li {
                        display: none;
                        &:last-of-type {
                            display: block;
                        }
                    }
                }
            }
        }
        .burger-menu {
            display: block;
            right: 15px;
        }
    }
}