.cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
        width: 30%;
        margin-bottom: 50px;
        .img-container {
            position: relative;
            img {
                width: 100%;
                height: auto;
                transition: scale 0.4s ease-in-out;
                border-radius: 5px;
            }
            &::before {
                position: absolute;
                content: '';
                left: -8px;
                top: -8px;
                width: 100%;
                height: 100%;
                border: 2px solid $light-green;
                transition: all 0.4s ease-in-out;
                z-index: -1;
                border-radius: 5px;
            }
        }
        p {
            color: black;
        }
        &:hover {
            .img-container {
                img {
                    scale: 1.04;
                }
                &::before {
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
.contact-card {
    background-color: $navy;
    width: 100%;
    max-width: 800px;
    border-radius: 5px;
    // box-shadow: 2px 3px 11px grey;
    .contact-card-wrapper {
        padding: 40px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
            margin-bottom: 40px;
            text-align: left;
        }
        p {
            color: white;
            margin: 0 0 40px 0;
        }
    }
    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
}
#map {
    border: 0;
    width: 50%;
    height: 450px;
    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
}
@media only screen and (max-width: 1000px) {
    .cards {
        .card {
            width: 47%;
        }
    }
}
@media only screen and (max-width: $mobile) {
    .cards {
        .card {
            width: 100%;
        }
    }
}