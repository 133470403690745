@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    margin: 0;
    color: $light-green;
    font-size: 35px;
    // line-height: 80px;
    text-shadow: 3px 3px #000000;
    @media only screen and (max-width: 450px) {
        font-size: 20px;
        // line-height: 72px;
    }
}

h2 {
    color: $green;
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 80px 0;
    @media only screen and (max-width: 450px) {
        font-size: 35px;
        margin: 0 0 60px 0;
    }
}

h3 {
    color: $navy;
    font-size: 24px;
    margin: 20px 0;
    @media only screen and (max-width: 450px) {
        font-size: 20px;
    }
}

p {
    color: white;
    font-size: 18px;
    line-height: 24px;
    @media only screen and (max-width: 450px) {
        font-size: 16px;
        line-height: 21px;
    }
    &.black {
        color: black;
    }
}

a {
    text-decoration: none;
    position: relative;
    &.link {
        font-weight: 600;
        color: white;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &.button {
        display: inline-block;
        padding: 20px 40px;
        font-size: 22px;
        font-weight: 600;
        color: white;
        background-color: $navy;
        border-radius: 5px;
        box-shadow: 2px 3px 11px black;
        &:hover {
            background-color: $navy;
        }
        @media only screen and (max-width: $mobile) {
            font-size: 18px;
            padding: 15px 30px;
        }
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        position: relative;
    }
    &.contact-list {
        li {
            margin: 25px 0 0 0;
            font-size: 18px;
            font-weight: 500;
            color: white;
            @media only screen and (max-width: 450px) {
                font-size: 16px;
            }
            a {
                display: inline-block;
                vertical-align: middle;
                color: white;
                text-decoration: underline;
                padding-left: 40px;
                &:hover {
                    text-decoration: none;
                }
            }
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                content: '';
                vertical-align: middle;
                display: inline-block;
                width: 22px;
                height: 22px;
                background-size: contain;
            }
            &.phone {
                &::before {
                    background: url(../assets/imgs/phone-icon.svg) no-repeat center;
                }
            }
            &.email {
                &::before {
                    background: url(../assets/imgs/email-icon.svg) no-repeat center;
                }
            }
            &.address {
                padding-left: 40px;
                &::before {
                    background: url(../assets/imgs/address-icon.svg) no-repeat center;
                }
            }
        }
    }
}

.text-center {
    text-align: center;
}

.no-wrap {
    white-space: nowrap;
}
