footer {
    background-color: $dark-grey;
    padding: 50px 0 30px 0;
    width: 100%;
    .container {
        .social-links {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            a {
                margin: 0 20px;
                svg {
                    transition: fill .3s ease-in-out;
                    @media only screen and (max-width: $mobile) {
                        width: 35px;
                    }
                }
                &:hover {
                    svg {
                        fill: white;
                    }
                }
            }
        }
        p {
            margin: 0;
        }
    }
}