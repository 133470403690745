// heights
$header-height: 110px;
$header-height-minimised: 60px;
$mobile-header-height: 70px;

// devices
$mobile: 450px;
$desktop: 1920px;

// colors
$dark-grey: #171515;
$green: #006f37;
$light-green: #ddeec3;
$navy: #081229;
$light-navy: #234569;