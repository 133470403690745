.hero {
    position: relative;
    height: calc(100vh - $header-height);
    margin-top: $header-height;
    // background: url("./assets/imgs/forest.jpeg") no-repeat center;
    background-color: $navy;
    background-size: cover;
    overflow: hidden;
    &::before {
        position: absolute;
        content: '';
        top: -$header-height;
        height: $header-height;
        width: 100%;
        background-color: $dark-grey;
    }
    .bg-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.7;
    }
    .hero-text {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%,-50%);
        margin: auto;
        width: calc(100% - 40px);
        max-width: 700px;
        text-align: center;
        img {
            margin: 0 auto;
            max-width: 50vw;
            padding-top: 30px;
        }
        p {
            font-size: 28px;
            line-height: 45px;
            font-weight: 600;
            text-shadow: 5px 5px 12px #000000;
            margin: 30px 0;
        }
    }
    @media only screen and (max-width: 1100px) {
        height: calc(100vh - $mobile-header-height);
        margin-top: $mobile-header-height;
    }
}
